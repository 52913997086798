/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 92%;
  top: 40px;
}

.bm-burger-button:focus {
  outline: none !important;
  outline-offset: none !important;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
  border-radius: 5px;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #50565c;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  width: 200px;
}

/* General sidebar styles */
.bm-menu {
  background: #6c757d;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Individual item */
.bm-item {
  box-sizing: border-box;
  color: #fff;
  cursor: auto;
  display: block;
  font-size: 20px;
  height: 45px;
  text-decoration: none;
  letter-spacing: 1px;
  margin: auto;
  box-shadow: inset 0 -1px rgb(0 0 0 / 20%);
  padding: 0.5em;
}

.bm-item:hover {
  background-color: #5f666d;
}

.bm-item:focus {
  outline: none !important;
  outline-offset: none !important;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

@media only screen and (max-width: 500px) {
  .bm-burger-button {
    left: 86%;
  }
}
