.App {
  text-align: center;
  display: grid;
  grid-template-columns: 1fr;
}

.AppLogo {
  pointer-events: none;
  width: 400px;
  height: 400px;
}

.AppHeader {
  background-color: white;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  height: 92vh;
}

.heading {
  color: #212529;
  margin: 0;
  font-size: 3.5rem;
}

.headingInitials {
  color: #6c757d;
  margin: 0;
  line-height: 50%;
  font-size: 2.4rem;
}

.subheading {
  color: #6c757d;
  width: 50%;
  margin: auto;
  overflow: scroll;
  margin-top: 1.5%;
}

.AppLink {
  max-width: 4%;
  padding: 10px;
}

.socials {
  margin: 1.5% auto;
}

.divider {
  position: relative;
  margin-top: 9px;
  height: 1px;
}

.div-transparent:before {
  content: "";
  position: absolute;
  top: 0;
  left: 5%;
  right: 5%;
  width: 90%;
  height: 1px;
  background-image: linear-gradient(
    to right,
    transparent,
    rgb(48, 49, 51),
    transparent
  );
}

@media only screen and (max-width: 1000px) and (min-width: 501px) {
  .AppLink {
    max-width: 5%;
    padding: 8px;
  }
}

@media only screen and (max-width: 500px) {
  .AppLogo {
    margin-top: 20%;
    height: 300px;
    width: 300px;
  }
  .AppHeader {
    height: 90vh;
  }

  .AppLink {
    max-width: 10%;
    padding: 8px;
  }

  .heading {
    font-size: 3rem;
    margin-top: 3%;
  }

  .headingInitials {
    font-size: 2.2rem;
    line-height: 50%;
  }

  .subheading {
    margin-top: 4%;
    width: 80%;
    overflow: scroll;
  }

  .socials {
    margin: 5% auto;
  }
}
