.contactBlock {
  display: flex;
}

.contact-form {
  margin: 5% 5% 5% 5%;
  width: 50%;
  padding: 0 5% 5% 5%;
}

.contact-details {
  display: flex;
  text-decoration: none;
}

.maps {
  margin: 5% 5% 5% 5%;
  width: 50%;
}

img {
  width: 40%;
  border-radius: 50%;
  display: block;
  margin: auto;
}

button {
  background-color: lightgray;
  border-radius: 4px;
  margin: 5px 5px 18px 0;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  width: 50%;
  text-decoration: none;
}

button:hover {
  background-color: darkgray;
}

a {
  text-decoration: none;
  color: black;
}

.contactTitle {
  margin-bottom: 0;
}

.contact-heading {
  margin: 0;
  text-align: center;
  line-height: 90%;
}

.contact-subheading {
  text-align: center;
  color: #6c757d;
  font-size: 2.4rem;
  margin: 0 0 1% 0;
}

.contact-job {
  text-align: center;
  color: #6c757d;
  font-size: 1.5rem;
  margin: 0 0 1% 0;
}

@media only screen and (max-width: 500px) {
  .maps {
    display: none;
  }

  .AppLogo {
    margin-top: 20%;
    height: 300px;
    width: 300px;
  }

  .AppHeader {
    height: 90vh;
  }

  .AppLink {
    max-width: 10%;
    padding: 8px;
  }

  .contactTitle {
    display: none;
  }

  .contact-heading {
    font-size: 3rem;
    margin-top: 3%;
  }

  .contact-subheading {
    margin-top: 4%;
    overflow: scroll;
  }

  .contact-form {
    width: 100%;
    margin-top: 30%;
  }

  .headingInitials {
    font-size: 2.2rem;
    line-height: 50%;
  }

  .socials {
    margin: 5% auto;
  }
}
